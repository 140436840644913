const IMAGE_PATH = '/images/hero.jpg'

export const Hero = () => {
  return (
    <section className="h-48 w-full md:h-60">
      <img
        alt="hero-image"
        aria-label="hero-image"
        className="h-full w-full object-cover"
        src={IMAGE_PATH}
      />
    </section>
  )
}
