import { json } from '@remix-run/node'
import { useLoaderData } from '@remix-run/react'

import { TopPage } from '~/features'
import { TopFooter, TopHeader } from '~/features/common'
import { getAllPrefectures } from '~/models/master.server'
import { Prefecture } from '~/models/types'

const getPrefecturesData = async (): Promise<{
  prefectures: Prefecture[]
} | null> => {
  const prefectures = await getAllPrefectures()
  if (!prefectures) return null

  return { prefectures }
}

export const loader = async () => {
  const data = await getPrefecturesData()

  if (!data)
    throw new Response('Not Found', { status: 404, statusText: 'Not Found.' })

  return json(data)
}

export default function Index() {
  const { prefectures } = useLoaderData<typeof loader>()
  return (
    <div className="flex min-h-screen flex-col">
      <TopHeader />
      <div className="flex-grow">
        <TopPage prefectures={prefectures} />
      </div>
      <TopFooter />
    </div>
  )
}
