import { SectionHeading } from '~/components'
import { Prefecture } from '~/models/types'

import { AreaList } from './area-list'
import { MainArea } from './main-area'

export const AreaSearch = ({ prefectures }: { prefectures: Prefecture[] }) => {
  return (
    <section aria-label="search-by-area" className="flex flex-col gap-y-4">
      <SectionHeading aria-label="search-by-area-heading">
        エリアから探す
      </SectionHeading>
      <div className="flex flex-col gap-y-6">
        <MainArea prefectures={prefectures} />
        <AreaList prefectures={prefectures} />
      </div>
    </section>
  )
}
