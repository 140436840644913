import { Container } from '~/components'
import { Prefecture } from '~/models/types'

import {
  AreaSearch,
  Hero,
  OfficeMapDescription,
  SearchByMap,
} from '../components'

type Props = {
  prefectures: Prefecture[]
}

export const TopPage = ({ prefectures }: Props) => {
  return (
    <main className="flex w-full flex-col gap-y-6 pb-10">
      <Hero />
      <Container className="flex flex-col gap-y-6">
        <OfficeMapDescription />
        <SearchByMap />
        <AreaSearch prefectures={prefectures} />
      </Container>
    </main>
  )
}
