import { useMemo } from 'react'

import { Link } from '@remix-run/react'

import { Badge } from '~/components'
import { Prefecture } from '~/models/types'

type AreaProps = {
  prefecture: Prefecture
}

const Area = ({ prefecture }: AreaProps) => {
  return (
    <Link
      aria-label="area"
      className="relative h-24 overflow-hidden rounded border border-grayBtn hover:opacity-70 md:h-[84px]"
      to={`/prefectures/${prefecture.slug}`}
    >
      <img
        alt={prefecture.name}
        className="h-full w-full object-cover"
        src={`/images/${prefecture.slug}.png`}
      />
      <Badge
        aria-label={prefecture.slug}
        className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform whitespace-nowrap rounded-full bg-white text-center text-body-bold text-brand"
      >
        {prefecture.name}
      </Badge>
    </Link>
  )
}

type MainAreaProps = {
  prefectures: Prefecture[]
}

export const MainArea = ({ prefectures }: MainAreaProps) => {
  const selectedPrefectures = useMemo(() => {
    const prefectureNames = [
      '東京都',
      '神奈川県',
      '愛知県',
      '大阪府',
      '京都府',
      '福岡県',
    ]
    return prefectureNames
      .map(
        (prefName) =>
          prefectures.find((prefecture) => prefecture.name === prefName) ||
          null,
      )
      .map((prefecture) => {
        if (prefecture) {
          return {
            ...prefecture,
            name: prefecture.name.replace(/(都|県|府)$/, ''),
          }
        }
        return null
      })
      .filter((prefecture) => prefecture !== null)
  }, [prefectures])

  return (
    <div
      aria-label="main-area"
      className="grid grid-cols-[repeat(3,1fr)] gap-2 md:grid-cols-[repeat(6,1fr)] md:gap-4 lg:grid-cols-[repeat(6,_124px)]"
    >
      {selectedPrefectures.map((prefecture) => (
        <Area key={prefecture.id} prefecture={prefecture} />
      ))}
    </div>
  )
}
