// app/features/top-page/components/area-search/AreaList.tsx
import { LinkList } from '~/features/common/link-list'
import { Prefecture } from '~/models/types'

type AreaGroup = {
  areaName: string
  prefectures: { slug: string; active: boolean }[]
}

const AREA_GROUPS: AreaGroup[] = [
  {
    areaName: '北海道・東北',
    prefectures: [
      { slug: 'hokkaido', active: true },
      { slug: 'aomori', active: true },
      { slug: 'akita', active: true },
      { slug: 'yamagata', active: true },
      { slug: 'iwate', active: true },
      { slug: 'miyagi', active: true },
      { slug: 'fukushima', active: true },
    ],
  },
  {
    areaName: '関東',
    prefectures: [
      { slug: 'tokyo', active: true },
      { slug: 'kanagawa', active: true },
      { slug: 'saitama', active: true },
      { slug: 'chiba', active: true },
      { slug: 'tochigi', active: true },
      { slug: 'ibaraki', active: true },
      { slug: 'gunma', active: true },
    ],
  },
  {
    areaName: '中部・北陸',
    prefectures: [
      { slug: 'aichi', active: true },
      { slug: 'gifu', active: true },
      { slug: 'shizuoka', active: true },
      { slug: 'mie', active: true },
      { slug: 'niigata', active: true },
      { slug: 'yamanashi', active: true },
      { slug: 'nagano', active: true },
      { slug: 'ishikawa', active: true },
      { slug: 'toyama', active: true },
      { slug: 'fukui', active: true },
    ],
  },
  {
    areaName: '近畿',
    prefectures: [
      { slug: 'osaka', active: true },
      { slug: 'hyogo', active: true },
      { slug: 'kyoto', active: true },
      { slug: 'shiga', active: true },
      { slug: 'nara', active: true },
      { slug: 'wakayama', active: true },
    ],
  },
  {
    areaName: '中国・四国',
    prefectures: [
      { slug: 'okayama', active: true },
      { slug: 'hiroshima', active: true },
      { slug: 'tottori', active: true },
      { slug: 'shimane', active: true },
      { slug: 'yamaguchi', active: true },
      { slug: 'kagawa', active: true },
      { slug: 'tokushima', active: true },
      { slug: 'ehime', active: true },
      { slug: 'kochi', active: true },
    ],
  },
  {
    areaName: '九州',
    prefectures: [
      { slug: 'fukuoka', active: true },
      { slug: 'saga', active: true },
      { slug: 'nagasaki', active: true },
      { slug: 'kumamoto', active: true },
      { slug: 'oita', active: true },
      { slug: 'miyazaki', active: true },
      { slug: 'kagoshima', active: true },
      { slug: 'okinawa', active: true },
    ],
  },
]

type Props = {
  prefectures: Prefecture[]
}

export const AreaList = ({ prefectures }: Props) => {
  return (
    <div aria-label="area-list" className="flex flex-col gap-y-6 md:gap-y-4">
      {AREA_GROUPS.map(({ areaName, prefectures: groupPrefectures }) => {
        const links = groupPrefectures.map(({ slug, active }) => {
          const prefecture = prefectures.find((p) => p.slug === slug)
          return prefecture
            ? {
                text: prefecture.name,
                href: active ? `/prefectures/${slug}` : undefined,
              }
            : { text: '', href: undefined }
        })
        return (
          <div key={areaName}>
            <h2 className="mb-4 text-base text-body-bold text-textPrimary md:mb-2">
              {areaName}
            </h2>
            <LinkList ariaLabel={areaName} links={links} />
          </div>
        )
      })}
    </div>
  )
}
