import { SectionHeading } from '~/components'
import { LinkToMap } from '~/features/common'

export const SearchByMap = () => {
  return (
    <section>
      <SectionHeading className="mb-4">地図で探す</SectionHeading>
      <LinkToMap className="h-[174px] md:h-[132px]" />
    </section>
  )
}
