export const OfficeMapDescription = () => {
  return (
    <div aria-label="office-map-description" className="flex flex-col gap-y-1">
      <h2 className="text-body-bold">officeMAPとは？</h2>
      <p className="text-body-s">
        全国のシェアオフィス・レンタルオフィス・コワーキングスペースなどのフレキシブルオフィスを検索できるサイトです。
      </p>
    </div>
  )
}
